import React, { Suspense, lazy } from "react";
import ReactGA from "react-ga";
import "./styles/tailwind.css";
import "./App.scss";

const Header = lazy(() => import("./components/Header"));

// import Main from "./components/Main";
// import Footer from "./components/Footer";

ReactGA.initialize("UA-149970511-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <div className="App">
            <Suspense fallback={<div>Loading...</div>}>
                <Header />
                {/* <Main /> */}
                {/* <Footer /> */}
            </Suspense>
        </div>
    );
}

export default App;
